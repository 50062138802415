<template>
    <b-container class="calc__container">
        <div style="width: 100%" id="tcpp-iframe-kfz"></div>
    </b-container>
</template>

<script>
import $ from "jquery";
export default {
    created() {
        let externalScript = document.createElement("script");
        externalScript.setAttribute(
            "src",
            "https://form.partner-versicherung.de/widgets/68185/tcpp-iframe-kfz/kfz-iframe.js"
        );
        $(document).ready(function() {
            document.body.appendChild(externalScript);
        });
    }
};
</script>

<style scoped>
.calc__container {
    margin-top: 25px;
}

@media (min-width: 992px) {
    .calc__container {
        width: 800px;
    }
}
</style>
